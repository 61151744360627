import { emailRegex } from "@/lib/validations/email";
import { OptionalField, GeneratorData, Section, TemplateData, BaseField } from "@/types/formGenerator";
import { getDateStringMMDDYYYY } from "@/utils/dateManipulation";
import { atLeafObject, flattenObjectRetainValues, getObjectValueByPath } from "@/utils/objectManipulation";
import { getFirstRegexMatchArray, getBeforeMatch, CustomRegExpExecArray, createCustomRegExpExecArray } from "@/utils/regex";
import { replacePlaceholders } from "@/utils/replacePlaceholders";
import {
  generateAllKeyVariationsByRemovingSubstrings,
  isWithinCharacterDistance,
  removeLeadingAndTrailingWhiteSpace,
  stringHasChar,
  stringHasLetterOrNumber,
  stringIsWhitespace,
  strPossiblyAName,
} from "@/utils/stringManipulation";
import { QuizTwoTone } from "@mui/icons-material";
import { debug } from "console";
import { cloneDeep, get, remove, result } from "lodash";

export const referencedFieldsRegex = new RegExp(/\{[A-Za-z0-9]+_[A-Za-z0-9]+[^}]*\}/g);
export const lazyReferencedFieldsRegex = new RegExp(/{([^{}]+)}/g);

const convertToNumberOrOne = (value: any, fallback: number = 1) => {
  const parsedValue = parseInt(value, 10); // Try to parse the value as an integer
  return isNaN(parsedValue) ? fallback : parsedValue; // If parsing fails, return the fallback value; otherwise, return the parsed value
};

export const containsReferencedFields = (input: string | null = ""): boolean => {
  const regex = referencedFieldsRegex;
  regex.lastIndex = 0; // Resetting the last index to ensure the regex starts from the beginning in global mode
  return regex.test(input ?? "");
};

export const cleanReferencedFieldValue = (input: string): string => {
  input = removeLeadingAndTrailingWhiteSpace(input);
  input = removeReferencedFields(input);
  return input;
};

//check if a given value could be generated by a reference string with referenced fields that have syntax "{XXX_YYY}" with a permissible number of character off errors
export const referencedStringCouldGenerateValue = (value: string, referencedString: string): boolean => {
  // Regex to match referenced fields in the format {fieldName}
  const regex = referencedFieldsRegex;

  // Split the reference string into segments
  const segments = referencedString.split(regex).filter((segment) => segment.trim() !== "");

  let currentIndex = 0;

  for (const segment of segments) {
    const index = value.indexOf(segment, currentIndex);

    if (index === -1) {
      // Segment not found in target string
      return false;
    } else {
      // Move the current index to the end of the found segment
      currentIndex = index + segment.length;
    }
  }

  // All segments found in the correct order
  return true;
};

//Given a string that can contain already populated referenced fields that had syntax "{XXX_YYY}", and an array of strings with unpopped referenced fields, check if the value is in the array, or close to being in the array. If referenced fields werent a thing we would just do   //Object.values(dependentsMap).includes(dependentValue)
export const valueIsInReferencedStringArr = (
  value: string,
  referencedStringArr: string[],
  referencedFields?: Record<string, any>,
  permissibleNumCharacterDiff: number = 0
): boolean => {
  //clean up the value
  value = removeLeadingAndTrailingWhiteSpace(value);

  //loop over all elements in the array
  for (let i = 0; i < referencedStringArr.length; i++) {
    //clean up the referenced string
    let referencedString = removeLeadingAndTrailingWhiteSpace(referencedStringArr[i]);

    //replace as many placeholders in the referenced string as possible
    if (referencedFields) {
      referencedString = replacePlaceholders(referencedString, referencedFields);
    }

    //if there are still unpopulated referenced fields,
    if (containsReferencedFields(referencedString)) {
      if (referencedStringCouldGenerateValue(value, referencedString)) {
        return true;
      }
    } else {
      //We compare the value to the referenced string directly
      if (permissibleNumCharacterDiff === 0 && value === referencedString) {
        return true;
      }
      if (permissibleNumCharacterDiff > 0 && isWithinCharacterDistance(value, referencedString, permissibleNumCharacterDiff)) {
        return true;
      }
    }
  }
  return false;
};

export const testRegex = (input: string, regex = referencedFieldsRegex): boolean => {
  return regex.test(input);
};

export const getReferencedFieldId = (input: string): string => {
  //if there is a referenced field in the string, return the first one
  const match = input.match(referencedFieldsRegex);
  if (match) {
    input = match[0];
  } else {
    input = removeLeadingAndTrailingWhiteSpace(input);
  }

  //remove the {} from the string if present and only if present at the start and end of the string
  input = input.replace(/(^\{|\}$)/g, "");

  //remove any spliced tokens from the string,
  input = removeSplicedTokensFromString(input);

  //remove all after and including first instance of a whitespace
  input = getBeforeMatch(input, /\s/);

  input = removeLeadingAndTrailingWhiteSpace(input);

  return input;
};

export const getReferencedFields = (input: string, removeBounds = true, regex = referencedFieldsRegex): string[] => {
  //returns an array of strings that match the regex, with the bounds optionally removed
  if (!input || typeof input !== "string") return [];
  const matches = input.match(regex) || [];
  if (removeBounds) {
    return matches.map((match) => match.slice(1, -1));
  } else {
    return matches;
  }
};

export const removeReferencedFields = (input: string, regex = referencedFieldsRegex): string => {
  return input.replace(regex, "");
};

export const getConditionalOperators = (inputString: string, operators = ["!==", "===", "&&", "||"]): string[] => {
  // returns an array of conditional operators that match the regex
  // Escape special characters and join operators into a single regex pattern
  const escapedOperators = operators.map((op) => op.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"));
  const regexPattern = escapedOperators.join("|");
  const regex = new RegExp(regexPattern, "g");

  // Match and return all occurrences of the operators in order
  const matches = inputString.match(regex);
  return matches || [];
};

/**
 * Parses nested bracketed fields and returns the outermost match.
 * @param input - The input string to parse nested brackets.
 * @returns An array containing the outermost matched fields.
 * @example
 * @warn returnOnFirstFound as false is not supported yet
 */
export const parseNestedFields = (
  input: string,
  returnOnFirstFound = true,
  ignoreQuotes = false,
  ignoreComments = false,
  enforceStrictReferencedFieldCheck = false
): CustomRegExpExecArray => {
  const results: CustomRegExpExecArray = createCustomRegExpExecArray([], 0, input);
  let startIndex = -1;
  let bracketDepth = 0;
  let quoteDepth = 0;
  let skippingToNextNewLine = false;
  let ignoringBrackets = false;

  let isValidStrictReferencedField = false; //a strict referenced field is one that has a _ in it and follows the regex "referencedFieldsRegex", a lazy one is just {XXX} ie "lazyReferencedFieldsRegex"
  let failedStrictReferencedFieldCheck = false;
  let isExpectingWord = false;
  let gotWord = false;
  let isExpectingUnderScore = false;
  let gotUnderScore = false;

  if (input.includes("{test}")) debugger;

  for (let i = 0; i < input.length; i++) {
    const curChar = input[i];
    const curCharP1 = input[i + 1];
    if (enforceStrictReferencedFieldCheck && bracketDepth > 0) {
      //is it not a valid strict referenced field yet, and hasnt failed the check yet
      if (!isValidStrictReferencedField && !failedStrictReferencedFieldCheck) {
        //its not confirmed to be a strict referenced field yet, once it is all characters are valid afterwards untill final bracket
        if (bracketDepth > 1) {
          failedStrictReferencedFieldCheck = true;
        } else if (isExpectingWord && stringHasLetterOrNumber(curChar)) {
          gotWord = true;
          if (gotUnderScore) {
            //we have a valid strict referenced field
            isValidStrictReferencedField = true;
          }
          isExpectingUnderScore = true;
        } else if (isExpectingUnderScore && curChar === "_") {
          gotUnderScore = true;
          if (!gotWord) {
            failedStrictReferencedFieldCheck = true;
          }
          isExpectingUnderScore = false;
        } else if (gotWord && stringIsWhitespace(curChar)) {
          failedStrictReferencedFieldCheck = true;
        }
      }
    }
    if (ignoreQuotes && curChar === '"') {
      quoteDepth++;
      if (quoteDepth % 2 === 0) {
        quoteDepth = 0;
      }
      ignoringBrackets = quoteDepth > 0;
    }
    if (curChar === "\n") {
      quoteDepth = 0;
      skippingToNextNewLine = false;
    }
    if (ignoringBrackets || skippingToNextNewLine) {
      continue;
    }
    if (quoteDepth !== 1 && ignoreComments && ((curChar === "/" && curCharP1 === "/") || curChar === "#" || (curChar === "/" && curCharP1 === "*"))) {
      //skip to next line
      skippingToNextNewLine = true;
      continue;
    }

    if (curChar === "{") {
      if (
        input.includes("Please see below for summary of our appointment. More information can be found on additional pages/attachments as applicable.")
      ) {
        //debugger;
      }
      if (bracketDepth === 0) {
        startIndex = i;
      }
      bracketDepth++;
      if (bracketDepth === 1) {
        isExpectingWord = true;
      }
    } else if (curChar === "}") {
      bracketDepth--;
      if (bracketDepth === 0 && startIndex !== -1) {
        if (!enforceStrictReferencedFieldCheck || isValidStrictReferencedField) {
          results.push(input.slice(startIndex, i + 1));
          results.index = startIndex;
          startIndex = -1;
          if (returnOnFirstFound) {
            break;
          }
        }
        //reset all the strict referenced fields
        isValidStrictReferencedField = false;
        failedStrictReferencedFieldCheck = false;
        isExpectingWord = false;
        gotWord = false;
        isExpectingUnderScore = false;
        gotUnderScore = false;
      }
    }
  }

  return results;
};

export const findFirstSpaceAfterOpeningBrace = (input: string) => {
  let insideBraces = false;

  for (let i = 0; i < input.length; i++) {
    if (input[i] === "{") {
      insideBraces = true;
    } else if (insideBraces && input[i] === " ") {
      return i;
    }
  }

  return -1; // Return -1 if no such space is found
};

export const captureWhiteSpaceUntilNestedClosingBracket = (input: string, excludeClosingBracket = true): CustomRegExpExecArray => {
  const match: CustomRegExpExecArray = createCustomRegExpExecArray([], 0, input);
  //regex to capture the first instance of a whitespace from the start of the string, that is found after an opening bracket
  const indexOfFirstSpaceAfterOpeningBrace = findFirstSpaceAfterOpeningBrace(input);
  if (indexOfFirstSpaceAfterOpeningBrace === -1) {
    return match;
  }
  match.index = indexOfFirstSpaceAfterOpeningBrace;
  //capture the string from the position indicated by the indexOfFirstSpaceAfterOpeningBrace to the closing bracket, do not stop for nested brackets
  //current depth is 1 since we are already inside a bracket
  let bracketDepth = 1;
  for (let i = indexOfFirstSpaceAfterOpeningBrace; i < input.length; i++) {
    if (input[i] === "{") {
      bracketDepth++;
    } else if (input[i] === "}") {
      bracketDepth--;
      if (bracketDepth === 0) {
        match.push(input.slice(indexOfFirstSpaceAfterOpeningBrace, excludeClosingBracket ? i : i + 1));
        break;
      }
    }
  }
  return match;
};

//an advanced reference field is one that has brackets on the outside and <> on the inside, like {sect0_field1<sect1_field1>}. Inside the <> are 2 numbers seperated by a comma
//if inside the <> there is only one letters followed by a number, we use the letters as a delimiter, and the number as the index for the resultant array
// or an advanced reference field is one that has spaces after rhe section_field within the {} such as {sect0_field1 *other stuff*}
export const getAllReferencedFieldDetails = (
  input: string,
  regexArray = [referencedFieldsRegex],
  allowNestedFields: boolean = true,
  enforceStrictReferencedField: boolean = false
): {
  ids: string[];
  fieldIndicies: number[][];
  spliceIndicies: number[][];
  delimiters: (string | null)[];
  operationLogic: (string | null)[];
} => {
  let ids = [];
  let fieldIndicies = [];
  let spliceIndicies = [];
  let delimiters = [];
  let operationLogic = [];
  let match: RegExpExecArray | null | CustomRegExpExecArray = null;
  let advancedFieldMatch: RegExpExecArray | null = null;

  //TODO we need to specify an index to move along so we dont repeat the same match

  // the index of a fields details in the resultant arrays
  let field_idx = -1;
  //start at the beginning of the string and move along
  for (let i = 0; i < input.length; ) {
    match = null;
    advancedFieldMatch = null;

    if (
      input.includes(
        "Thank you for the referral of {patient_firstName} {patient_lastName}. It was a pleasure to meet with {patient_pronouns</1>} and I appreciate the opportunity to be involved in {patient_pronouns</2> === Hers ? Her : {patient_pronouns</2> === Theirs ? Their : {patient_pronouns</2>}}} treatment. Please see below for summary of our appointment. More information can be found on additional pages/attachments as applicable."
      )
    ) {
      //debugger;
    }

    //get the first available referencedField
    if (allowNestedFields) {
      //find a synthetic "regex" match via a nested field compatible search, works to find "{patient_pronouns</2> === Hers ? Her : {patient_pronouns</2> === Theirs ? Their : {patient_pronouns</2>}}}" for example
      match = parseNestedFields(input.substring(i), undefined, undefined, undefined, enforceStrictReferencedField);
    } else {
      match = getFirstRegexMatchArray(input.substring(i), regexArray ?? referencedFieldsRegex);
    }
    if (!match || !match[0]) {
      //no more matches
      break;
    }

    // Extract the string outside <>
    //ids.push(removeSplicedTokensFromString(match[1]));
    fieldIndicies.push([i + match.index, i + match.index + match[0].length]);
    spliceIndicies.push([]);
    delimiters.push(null);
    operationLogic.push(null);
    field_idx++;
    //move the index to the end of the match so we dont repeat it
    i += match.index + match[0].length + 1;

    //Does this match have advancedReference field logic? splices or a space before the end?
    const spliceTypeMatch = getFirstRegexMatchArray(match[0], [/\{([^{}]*<[^{}]*>)\}/g]);
    const spaceTypeMatch = getFirstRegexMatchArray(match[0], [/\{[^ ]+_[^ ]+ .+\}/g]);

    if (spaceTypeMatch) {
      match = spaceTypeMatch;
      ids.push(getReferencedFieldId(match[0]));

      //extract all strings after the first space untill and before the closing bracket
      // This regex captures everything after the first whitespace and before the closing bracket `}
      //const operationLogicMatch = match[0].match(/\{(\w+<\/\d+>) (\*=\s*.+?)\}/);
      const operationLogicMatch = captureWhiteSpaceUntilNestedClosingBracket(match[0], true);
      if (operationLogicMatch && operationLogicMatch[0]) {
        //operation logic was captured as everything after the first space and before the closing bracket
        operationLogic[field_idx] = operationLogicMatch[0];
      }
      //set the match as the first reference field for further parsing, the operation logic will be added back later

      //remove the opening bracket if present and any leading or trailing whitespace
      match[0] = match[0].replace(/^\{/, "").trim();
      //remove everything after the first space
      match[0] = getBeforeMatch(match[0], /\s/);
    } else if (spliceTypeMatch) {
      match = spliceTypeMatch;
      ids.push(getReferencedFieldId(match[0]));
    } else {
      ids.push(getReferencedFieldId(match[0]));
    }

    // Extract the indicies inside <>
    let indiciesMatch = match[0].match(/<(-?\d+),?(-?\d+)??>/); //will match <number> or <number1,number2> and capture the numbers
    if (indiciesMatch) {
      //numbers were captured
      const start = indiciesMatch[1] || "0"; // Default start index to "0" if not present
      const end = indiciesMatch[2] || indiciesMatch[1]; // If only one index, use it for both start and end
      spliceIndicies[field_idx] = [start, end].map(Number);
    } else {
      indiciesMatch = match[0].match(/<([^<>\d]*)(\d*)>/); //will match <lettersNumber> and capture the letter and number
      if (indiciesMatch) {
        //letters and numbers were captured
        delimiters[field_idx] = indiciesMatch[1];
        spliceIndicies[field_idx] = [Number(indiciesMatch[2]), 0]; // Default start index to "0" if not present
      }
    }
  }

  // Combine or process idMatches and indicies as needed
  // Currently, only idMatches is returned
  return { ids, fieldIndicies, spliceIndicies, delimiters, operationLogic };
};

export const removeSplicedTokensFromString = (input: string): string => {
  if (!input) {
    return input;
  }
  return input.replace(/<[^<>]*>/g, "");
};

//return the input string without the <>'s and their inner content from the original string. Only remove <>'s and inner content if found within {}
export const removeSplicedTokensFromReferencedFields = (input: string, regex = /\{[^{}]*<[^<>]*>[^{}]*\}/g): string => {
  if (!input) {
    return input;
  }
  // remove the tokens from the matched strings
  //console.log("input99", input);
  return input.replace(regex, (match) => removeSplicedTokensFromString(match));
};

export const removeOperatorsFromString = (inputString: string, operators = ["!==", "===", "&&", "||"]): string => {
  let modifiedString = inputString;

  operators.forEach((operator) => {
    // Escaping special characters for regex
    const escapedOperator = operator.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
    const regex = new RegExp(escapedOperator, "g");

    modifiedString = modifiedString.replace(regex, "");
  });

  return modifiedString;
};

export const extractWordsExcludingCharacters = (inputString: string, characterRegex = /[()]/g): string[] => {
  // Remove characters based on the provided regex, defaults to remove parentheses
  const stringWithoutCharacters = inputString.replace(characterRegex, "");

  // Split the remaining string by spaces to get the words
  const words = stringWithoutCharacters.split(/\s+/).filter((word) => word.length > 0);

  return words;
};

//Function W.I.P, can only handle one level of nesting
export const decodeConditionalFromString = (input: string): Record<string, any> => {
  //takes a string like "{sect0_field1 === `Tuesday`} || (({sect1_field1} === true) && ({sect1_field2} === false))" and returns an object that describes it with conditions like {OR:{{AND: {sect1_field1: true, sect1_field2: false}}, {sect0_field1: "Tuesday"}}
  let structuredObject: Record<string, any> = {};
  //const fields = getReferencedFields(input);						//get all the fields referenced in the string left to right
  //const conditionals = getConditionalOperators(input);	//get all the conditionals in the string left to right
  //let others = extractWordsExcludingCharacters(input);			//get all the static strings in the string left to right
  let i = 0; // index of the current character in the string
  let numTokens = 0; //number of tokens in the string
  let tokens: string[] = []; // the current word being built
  let conditional: string = "==="; //the current conditional being built
  while (i < input.length) {
    const currentChar = input[i];
    if (currentChar === "{") {
      //start of a field
      const field = input.slice(i + 1, input.indexOf("}", i + 1)); //get the field name
      tokens.push(field);
      i += field.length + 2; //skip the field and the closing bracket
    } else if (currentChar === "}") {
      //end of a field
      i++;
    } else if (currentChar === "(") {
      //start of a conditional
      //TODO maybe recursively call here?
      i++; //skip the conditional
    } else if (currentChar === ")") {
      //end of a conditional
      i++;
    } else if (currentChar === "=") {
      //probably a conditional get the next two characters
      conditional = input.slice(i, i + 3);
      i += 4;
    } else if (currentChar === "!") {
      //probably a conditional get the next two characters
      conditional = input.slice(i, i + 3);
      i += 4;
    } else if (currentChar === "&") {
      //probably a conditional get the next character
      conditional = input.slice(i, i + 2);
      i += 3;
    } else if (currentChar === "|") {
      //probably a conditional get the next character
      conditional = input.slice(i, i + 2);
      i += 3;
    } else if (currentChar === " " || currentChar === "\t") {
      //end of a token, probably not necessary
      i++;
      numTokens++;
    } else {
      //its a normal letter
      const idx1 = input.indexOf(" ", i + 1);
      const idx2 = input.indexOf(")", i + 1);
      const idx3 = input.indexOf("(", i + 1);
      const idxMax = Math.max(idx1, idx2, idx3);
      const field = input.slice(i, idxMax > i ? idxMax : undefined); //get the field name
      tokens.push(field);
      i += field.length + 1; //skip the space
      numTokens++;
    }
  }
  //build the object
  structuredObject[conditional] = { [tokens[0]]: tokens[1] };

  return structuredObject;
};

//takes an input value = "123456 {hello_ff}Dog"; returns an array of strings ["123456 ","Dog"] without the matches
export const spliceReferencedFieldsStringToArray = (value: string, regex = referencedFieldsRegex): string[] => {
  const result: string[] = [];
  let lastIndex = 0;

  value.replace(regex, (match, idx, match2, idx2, match3, idx3) => {
    if (!(lastIndex === idx)) {
      // Add the string before the match
      result.push(value.substring(lastIndex, idx));
    }

    // Update the lastIndex to the end of the current match
    lastIndex = idx + match.length;

    // Return value is not used, but required for replace function
    return match;
  });

  // Add the remaining part of the string, if any
  if (lastIndex < value.length) {
    result.push(value.substring(lastIndex));
  }

  return result;
};

export const getDefaultValuesFromTemplate = (template: TemplateData): Record<string, any> => {
  const defaultValues: Record<string, any> = {};

  // Iterate over each tab key in the tabs object
  for (const tab of Object.keys(template.tabs)) {
    // iterate over each section in tab
    for (const section of Object.keys(template["tabs"][tab].sections)) {
      // iterate over each field in section
      for (const field of Object.keys(template["tabs"][tab].sections[section].fields)) {
        // Create a composite key using section and title
        const newKey = `${section}_${field}`;
        // Set the default value for the field, defaulting to an empty string if value is undefined
        defaultValues[newKey] = template["tabs"][tab].sections[section].fields[field].value ?? undefined;
      }
    }
  }

  return defaultValues;
};

/**
 * Takes in user-ready data and formats it for the form, ensuring the object tree has a depth of 1.
 * This is ideal for use with form handling libraries like React Hook Form that prefer flat structures.
 *
 * @param inputSections An object containing sections, each with nested fields and values.
 * @returns An object with keys in the format `section_title` and their associated default values.
 */
export const getDefaultValueReactHookForm = (inputSections: { [key: string]: Section }): Record<string, any> => {
  const defaultTitleValues: { [key: string]: any } = {};

  // Iterate over each section entry
  for (const [section, sectionObj] of Object.entries(inputSections)) {
    if (!sectionObj || typeof sectionObj !== "object" || !sectionObj.fields) {
      // Skip sections that don't conform to the expected structure
      console.warn(`Skipping section ${section} due to invalid structure or missing 'fields'.`);
      continue;
    }

    const fields = sectionObj.fields;

    // Iterate over each field within the section
    for (const [title, obj] of Object.entries(fields)) {
      if (!obj || typeof obj !== "object") {
        // Skip invalid field objects
        console.warn(`Skipping field ${title} in section ${section} due to invalid object.`);
        continue;
      }

      // Create a composite key using section and title
      const newKey = `${section}_${title}`;
      // Set the default value for the field, defaulting to an empty string if value is undefined
      defaultTitleValues[newKey] = obj.value ?? "";
    }
  }

  return defaultTitleValues;
};

export const updateFormGeneratorFieldsWithNewValues = (generatorSections: GeneratorData, newValues: Record<string, any>): GeneratorData => {
  let assignmentCount = 0;
  const newGeneratorSections = cloneDeep(generatorSections);

  if (!generatorSections || !newValues) {
    console.error("generatorSections or newValues is missing");
    return generatorSections;
  }
  if (!generatorSections.sections) {
    console.error("generatorSections.sections is missing");
    return generatorSections;
  }

  // Loop over each section in the generatorSections object with a for loop
  for (const [sectionKey, sectionValue] of Object.entries(generatorSections.sections)) {
    if (!sectionValue.fields) {
      console.error("sectionValue.fields is missing");
      continue;
    }
    // Loop over each field in the sectionValue object with a for loop
    for (const [fieldKey, fieldValue] of Object.entries(sectionValue.fields)) {
      // Check if the newValues object has a key that matches the current fieldKey
      const depthOneFieldKey = `${sectionKey}_${fieldKey}`;
      if (newValues.hasOwnProperty(depthOneFieldKey)) {
        //assign the newValue to to the respective newGeneratorSections field value
        newGeneratorSections.sections[sectionKey].fields[fieldKey].value = newValues[depthOneFieldKey];
        assignmentCount++;
      } else {
        console.error("newValues does not have key", fieldKey);
      }
    }
  }

  if (assignmentCount !== Object.keys(newValues).length) {
    console.error("Not all keys in newValues were assigned to the generatorSections");
  }

  return newGeneratorSections;
};

// Define a map of case strings and their replacement values, these are global mapping consistent with all forms and templates

//Takes data of type GeneratorData and returns a new object with the same structure but with the default values formatted
const formatFormGeneratorFields = (
  generatorFields: { [key: string]: Record<string, any> } | string, // The generator fields to format
  referencedFields?: { [key: string]: Record<string, any> }, //for autofilling fields, contains the fields that are referenced in the generatorFields, includes relation data and other previous forms such as entry fields in a reports field
  minNumTextareaLines: number = 3,
  maxNumTextareaLines: number = 20,
  removeAllUnmatched: boolean = false
): { [key: string]: Record<string, any> } | string => {
  if (!generatorFields) {
    console.error("generatorFields is missing");
    return generatorFields;
  }
  const processedData: { [key: string]: Record<string, any> } = {};

  const replacements: Record<string, string> = {
    "{TODAY}": getDateStringMMDDYYYY(new Date()),
    "{NOW}": new Date().toLocaleTimeString(),
    "{USER}": "{relUser_prefix} {relUser_firstName} {relUser_lastName}",
  };

  // Define a regular expression pattern to match the case strings
  const regex = new RegExp(Object.keys(replacements).join("|"), "g");
  ////console.log("BEFOREgeneratorFields: ", generatorFields);
  //type is a string not a Record<string, any>
  if (typeof generatorFields === "string") {
    const formattedString = (generatorFields as string).replace(regex, (match) => {
      return replacements[match] || match; // Use the replacement value if it exists, otherwise keep the original match
    });
    if (removeAllUnmatched) {
      //match all the remaining {anything} and remove them
      return formattedString.replace(/\{[^}]+\}/g, "");
    }
    return formattedString;
  }

  const generatorFieldsFlat = flattenObjectRetainValues(generatorFields, undefined, undefined, undefined, "fields");

  //avoid mutating the original object, may be readonly if passed as redux store
  let writableGeneratorFields = JSON.parse(JSON.stringify(generatorFields)) as { [key: string]: Section };

  Object.entries(writableGeneratorFields).forEach(([sectionTitle, sectionFeatures]) => {
    // Loop over each section's titles and objects
    //console.log("sectionValue", fieldFeatures);
    Object.entries(sectionFeatures.fields).forEach(([fieldTitle, obj]) => {
      obj = obj as BaseField;
      //Does a value exist? obj.value could validly be false
      //console.log("obj.value", obj.value);
      //console.log("obj.value === undefined", obj.value === undefined);
      //console.log("obj", obj);
      //console.log("processedData", processedData);
      if (obj.value === undefined || obj.value === null || obj.hasOwnProperty("value") === false) {
        obj.value = "";
      }
      // Check if the value is a string
      if (typeof obj.value === "string") {
        const defaultValue: string = obj.value;
        // Use a loop to replace all occurrences of the case strings
        let updatedValue = defaultValue.replace(regex, (match) => {
          return replacements[match] || match; // Use the replacement value if it exists, otherwise keep the original match
        });

        // Replace all other placeholders with the referenced fields
        updatedValue = replacePlaceholders(updatedValue, { ...generatorFieldsFlat, ...referencedFields });
        //console.log("updatedValue", updatedValue);
        ////console.log("obj.value", obj.value);

        // Update the object's value with the updatedValue
        obj.value = updatedValue;

        //give every textarea an itemLines property
        if (obj.type === "textarea") {
          if (obj.itemLines) {
            obj.itemLines = convertToNumberOrOne(obj.itemLines, minNumTextareaLines);
          } else {
            obj.itemLines = obj.value.split("\n").length || 1;
          }
          obj.itemLines = Number(Math.min(maxNumTextareaLines, Math.max(minNumTextareaLines, obj.itemLines)));
        }
        // Check if the value consists of only whitespace characters and new lines
        if (/^\s*$/.test(obj.value)) {
          obj.value = ""; // Set value to an empty string
        }
      }
      //is there an options array?
      if (obj.options && obj.options.length > 0) {
        //MAYBE TODO, we will just reference the options array in the redux store, so we don't need to copy it here
        //dispatch the values to the options array redux store
      }
      // Put the modified obj back into sectionValue of the field within the generatorData.fields object called title
      sectionFeatures.fields[fieldTitle] = obj;
    });
    // Put the modified sectionValue back into processedData with the current key
    processedData[sectionTitle] = sectionFeatures;
  });
  ////console.log("AFTERgeneratorFields: ", processedData);
  return processedData;
};

interface PopulateTemplateResult {
  template?: Record<string, any>;
  newTemplate?: Record<string, any>;
  unShownFields?: Record<string, any> | undefined;
}

//Useful for populating an object with format obj.fields.section.field.value = "value", from a map of format {fieldSection_fieldName: "value"}
export const populateTemplateWithSavedFields = (
  template: Record<string, any>, // The template object to populate
  sections: Record<string, any> | any, // The saved fields to populate the template with
  unShownFields: Record<string, any> | undefined = undefined // The fields that were not matched to the template
): PopulateTemplateResult => {
  if (!sections || Object.entries(sections).length === 0) return { template, unShownFields }; // Return the template as is if there are no fields to populate it with
  let newTemplate = cloneDeep(template);
  //variable to check if all data was recovered from the db and saved to state
  let dispatchingAllSavedData = true;

  if (newTemplate) {
    // We use the template data from previous save and initalize it with the form saved fields - Note that form saved fields are saved in an object where section and field name are encoded into one key
    Object.entries(sections as object).forEach(([key, value]) => {
      //Decode section and field name key - Loop over each saved field from the Form collection and convert it from underscore format to each of the two depths of sub objects that represent the object keys
      const parts = key.split("_", 2);
      const SectionKey = parts[0];
      const FieldKey = parts[1];
      // Check if the first, and second level key exists in the object
      if (newTemplate.hasOwnProperty(SectionKey) && newTemplate[SectionKey]["fields"].hasOwnProperty(FieldKey)) {
        //Save value that matched template field
        /*
				console.log(
					"data.entryTemplate.fields[SectionKey][FieldKey].value",
					data.entryTemplate.fields[SectionKey][FieldKey].value
				);
				*/
        newTemplate[SectionKey]["fields"][FieldKey]["value"] = value;
      } else {
        dispatchingAllSavedData = false;
        if (unShownFields === undefined) unShownFields = {};
        unShownFields[key] = value;
      }
    });
  }
  if (!dispatchingAllSavedData) {
    console.warn("Not all data saved in the db for form will be shown. un-matched fields to template:", unShownFields);
  }
  return { newTemplate, unShownFields };
};

//given a key in the format "section_field" return true if the key is in the object, false otherwise
export const flatKeyInDeepObject = (key: string, obj: Record<string, any>): boolean => {
  if (!obj || !key) return false;
  if (obj.hasOwnProperty(key)) return true;
  if (Object.keys(obj).length === 0) return false;

  // Decode the key into its parts
  const parts = key.split("_");
  for (let i = 0; i < parts.length; ) {
    const part = parts[i];
    if (obj.hasOwnProperty(part)) {
      obj = obj[part];
      i++;
    } else if (obj.hasOwnProperty("sections")) {
      //sections is a reserved key in the object
      obj = obj["sections"];
    } else if (obj.hasOwnProperty("fields")) {
      //fields is a reserved key in the object
      obj = obj["fields"];
    } else {
      return false;
    }
  }
  return true;
};

export const getValueByKeyPath = (
  object: Record<string, any>,
  keyPath: string | string[],
  stringSplitBy: string = "_",
  insertKeyAtSplit: boolean = false,
  keyToAppend: string = "fields"
): any => {
  //given an object and a keyPath, return the value of the keyPath in the object if it exists
  let pathArr: string[] = [];
  let insertedPathArr: string[] = [];
  if (typeof keyPath === "string") {
    pathArr = keyPath.split(stringSplitBy ?? "_");
    if (insertKeyAtSplit) {
      // Iterate over the split array and construct the new array
      pathArr.forEach((item, index) => {
        insertedPathArr.push(item);
        // Add the insert string after each item except the last one
        if (index < pathArr.length - 1) {
          insertedPathArr.push(keyToAppend);
        }
      });
      pathArr = insertedPathArr;
    }
  } else {
    pathArr = keyPath;
  }

  return getObjectValueByPath(object, pathArr);
};

export const nestedObjectHasFlattenedKeyValue = (
  obj: Record<string, any>,
  key: string,
  value: any,
  insertKeyAtPathSplit: boolean = false,
  keyToInsert: string = "fields"
): boolean => {
  //goes through the object and checks if the key and value are present in the object. Key can be of "_" separated format where each part is a key in the object
  if (!obj || !key) return false;
  //variable to check if all data was recovered from the db and saved to state

  //Decode section and field name key - Loop over each saved field from the Form collection and convert it from underscore format to each of the two depths of sub objects that represent the object keys
  const pathValue = getValueByKeyPath(obj, key, "_", insertKeyAtPathSplit, keyToInsert);

  if (typeof pathValue === "object") {
    if (pathValue.hasOwnProperty("value")) {
      return pathValue.value === value;
    } else {
      return false;
    }
  } else if (typeof pathValue === "string") {
    return pathValue === value;
  }
  return false;
};

export const getUnMatchedFlattenedFieldsToNestedObject = (
  flattenedFields: Record<string, any> | any,
  nestedTemplate: Record<string, any>
): Record<string, any> => {
  // Return an object of all fields that are in the fields object (flattened key value) but not in the template object (nested key values)
  const unMatchedFields: Record<string, any> = {};
  for (const key of Object.keys(flattenedFields)) {
    const value = flattenedFields[key];
    if (!nestedObjectHasFlattenedKeyValue(nestedTemplate, key, value, true, "fields")) {
      unMatchedFields[key] = value;
    }
  }
  return unMatchedFields;
};

export default formatFormGeneratorFields;

// Traverses object top down and removes all sibling elements that are present at the same level as the key
export const removeKeySiblings = (
  obj: Record<string, any>,
  keyToKeep: string = "value",
  flattenKeyValue: boolean = true, //we return just the value of the key, effectively removing the keyToKey from the object but retaining its value
  addKeyIfNotFoundAtDeepestLeaf = false,
  valueForKeyIfNotFoundAtDeepestLeaf = undefined,
  stopAtDepth?: number | undefined, //stop at a certain depth unless the key is found before then
  currentDepth: number = 0
) => {
  // Remove the key and its siblings from the object
  // If the current level is not an object, return it as is
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  currentDepth++;

  // Check if key exists at this level
  if (obj.hasOwnProperty(keyToKeep)) {
    if (flattenKeyValue) {
      //return just the value of the key, ie flatten the object
      return obj[keyToKeep];
    }
    //else we retain the key and its value, all other keys are discarded
    return { [keyToKeep]: obj[keyToKeep] };
  } else {
    if (addKeyIfNotFoundAtDeepestLeaf && atLeafObject(obj)) {
      if (flattenKeyValue) {
        //return just the value of the key, ie flatten the object
        return valueForKeyIfNotFoundAtDeepestLeaf;
      }
      return { [keyToKeep]: valueForKeyIfNotFoundAtDeepestLeaf };
    }
    if (stopAtDepth && currentDepth >= stopAtDepth) {
      if (flattenKeyValue) {
        return valueForKeyIfNotFoundAtDeepestLeaf;
      }
      return { [keyToKeep]: valueForKeyIfNotFoundAtDeepestLeaf };
    }
    const result: Record<string, any> = {};
    // If a key is not found, recursively apply the function to all object properties
    for (const key in obj) {
      //if (key === "pleaseAdvise") debugger;
      result[key] = removeKeySiblings(
        obj[key],
        keyToKeep,
        flattenKeyValue,
        addKeyIfNotFoundAtDeepestLeaf,
        valueForKeyIfNotFoundAtDeepestLeaf,
        stopAtDepth,
        currentDepth
      );
    }

    return result;
  }
};

//given a sn object of form fields, return an object with name and email fields if other possible contacts are found to exist
export const findContacts = (
  obj: Record<string, any>,
  keysToSearch: string[] = ["email", "e-mail", "contact", "contactinformation", "contactinfo"], //text to identify field keys that may contain an email
  searchAllKeysForEmail: boolean = true,
  appendTextToKey: string[] = ["name"]
): Record<string, any>[] => {
  if (!obj) return [];

  //debugger;

  const result: Record<string, any>[] = [];

  for (const key of Object.keys(obj)) {
    const value = obj[key];
    if (typeof value === "object") {
      //recurse
      const additionalContactsFromNested = findContacts(value);
      result.push(...additionalContactsFromNested);
    } else if ((searchAllKeysForEmail || keysToSearch.some((substring) => key.includes(substring))) && typeof value === "string") {
      //check if the value is an email
      const emails = value.match(emailRegex);
      if (emails) {
        //we have emails, can we find a matching name for this email?
        //if (emails[0] === "drfrank@gmail.com") debugger;
        const hopefulNameKeys = generateAllKeyVariationsByRemovingSubstrings(key, keysToSearch, false, 50);

        const shortestHopefulNameKey = hopefulNameKeys.reduce((a, b) => (a.length < b.length ? a : b));
        const keysThatContainShortestHopefulNameKey = Object.keys(obj).filter((key) => key.includes(shortestHopefulNameKey));

        const allPossibleNameKeys = [...hopefulNameKeys, ...keysThatContainShortestHopefulNameKey];

        let foundNameKey = "";
        let foundName = "";
        for (const nameKey of allPossibleNameKeys) {
          const possibleName = obj[nameKey];
          if (possibleName && typeof possibleName === "string") {
            if (strPossiblyAName(possibleName)) {
              foundNameKey = nameKey;
              foundName = possibleName;
              break;
            }
          }
        }

        emails.forEach((email, idx) => {
          result.push({ nameKey: idx === 0 ? foundNameKey : "", name: idx === 0 ? foundName : "", emailKey: key, email });
        });
      }
    }
  }
  return result;
};
