import { debug } from "console";
import { round } from "./math";

//given a hex colour return the colour less vibrant
export const desaturateHex = (hex: string, factor: number = 0.5): string => {
  const rgb = hexToRgb(hex);
  const hsl = rgbToHsl(rgb);
  hsl[1] = hsl[1] * factor;
  return hslToHex(hsl);
};

export const lightenHex = (hex: string, factor: number = 1.1): string => {
  const rgb = hexToRgb(hex);
  const hsl = rgbToHsl(rgb);
  hsl[2] = hsl[2] * factor;
  return hslToHex(hsl);
};

export const colourIsDark = (hex?: string): boolean => {
  if (!hex) return false;
  const rgb = hexToRgb(hex);
  // equation weights for calculating if a colour is dark: https://www.w3.org/TR/AERT/#color-contrast
  return rgb[0] * 0.299 + rgb[1] * 0.587 + rgb[2] * 0.114 < 186;
};

export const hexToRgb = (hex: string): number[] => {
  const bigint = parseInt(hex.replace("#", ""), 16);
  return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
};

export const rgbToHsl = (rgb: number[]): number[] => {
  const r = rgb[0] / 255;
  const g = rgb[1] / 255;
  const b = rgb[2] / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0;
  let s = 0;
  const l = (max + min) / 2;

  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }

    h /= 6;
  }

  return [round(h * 360, 1), round(s * 100, 1), round(l * 100, 1)];
};

export const parseColorToHSL = (color: string): number[] => {
  if (color.startsWith("#")) {
    // Handle HEX to HSL conversion
    return hexToHsl(color);
  } else {
    // Handle HSL string
    return parseHSL(color);
  }
};

// Convert HEX to HSL
const hexToHsl = (hex: string): number[] => {
  // Remove the '#' if present
  hex = hex.replace("#", "");

  // Parse r, g, b values
  const r = parseInt(hex.substring(0, 2), 16) / 255;
  const g = parseInt(hex.substring(2, 4), 16) / 255;
  const b = parseInt(hex.substring(4, 6), 16) / 255;

  // Find max and min RGB values
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h = 0;
  let s = 0;
  const l = (max + min) / 2;

  if (max !== min) {
    const delta = max - min;
    s = l > 0.5 ? delta / (2 - max - min) : delta / (max + min);

    switch (max) {
      case r:
        h = (g - b) / delta + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / delta + 2;
        break;
      case b:
        h = (r - g) / delta + 4;
        break;
    }

    h /= 6;
  }

  return [Math.round(h * 360), Math.round(s * 100), Math.round(l * 100)];
};

export const parseHSL = (hslString: string): number[] => {
  // Split the string into its components
  const [h, s, l] = hslString
    .split(/\s+/) // Split by whitespace
    .map((val, i) => {
      // For hue (index 0), parse as a float
      if (i === 0) return parseFloat(val);

      // For saturation and lightness (indices 1 and 2), remove '%' and parse as a percentage
      return parseFloat(val.replace("%", ""));
    });

  return [h, s, l];
};

export const hslToHex = (hsl: number[]): string => {
  let [h, s, l] = hsl;
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0"); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

export const colourToHex = (colour: string | number[], withHash: boolean = true): string => {
  //hsl number array
  if (Array.isArray(colour)) return hslToHex(colour);
  if (typeof colour === "string") {
    //hsl string with spaces
    if (colour.includes(" "))
      return hslToHex(
        colour.split(" ").map((n) => {
          //remove % sign
          if (n.includes("%")) return parseFloat(n);
          return parseFloat(n);
        })
      );
    //hsl string with commas
    if (colour.includes(","))
      return hslToHex(
        colour.split(",").map((n) => {
          //remove % sign
          if (n.includes("%")) return parseFloat(n);
          return parseFloat(n);
        })
      );
    //hex string
    if (colour.includes("#")) return colour;
    else {
      if (withHash) return `#${colour}`;
    }
  }
  return withHash ? `#` : "";
};
